import {
  SET_ORDER,
  SET_ORDER_STORE,
  CLEAR_ORDER,
  SET_ORDER_TIP,
  SET_ORDER_TIPPERCENT,
  SET_INSTORE_ORDER,
  SET_ORDER_TOTAL
} from "../actions/types";
import { createReducer } from "redux-starter-kit";

const DEFAULT_STATE = {
  order: {
    company: {},
    store: {},
    lineItemGroups: [
      {
        lineItems: []
      }
    ],
    serviceMode: "pickUp",
    subtotal: 0,
    deliveryFee: 0,
    tax: 0,
    tip: 0,
    total: 0,
    convenienceFee: 0,
    ccSubTotal: 0,
    ccTax: 0,
    ccConvenienceFee: 0,
    ccTotal: 0,
  },
  accounting: {
    tipPercent: 0,
    customize: false,
  },
  nonCashFee: 0,
};

export default createReducer(DEFAULT_STATE, {
  [SET_ORDER]: (state, action) => {
    state.order = action.payload;
  },
  [SET_ORDER_STORE]: (state, action) => {
    state.order.store = action.payload;
  },
  [CLEAR_ORDER]: state => {
    state.order.lineItemGroups = [{ lineItems: [] }];
    state.order.subtotal = 0;
    state.order.deliveryFee = 0;
    state.order.tax = 0;
    state.order.tip = 0;
    state.order.total = 0;
    state.order.convenienceFee = 0;
    state.order.ccSubTotal = 0;
    state.order.ccTax = 0;
    state.order.ccTotal = 0;
    state.order.ccConvenienceFee = 0;
  },
  [SET_ORDER_TIP]: (state, action) => {
    const { subtotal, tax, deliveryFee, convenienceFee, ccSubTotal, ccTax } = state.order;
    const tip = parseFloat(action.payload.tip);
    const nonCashFeePercentage = parseFloat(action.payload.nonCashFeePercentage);
    const creditCardProgram = action.payload.creditCardProgram;
    const total = subtotal + tax + deliveryFee;
    const ccTotal = ccSubTotal + ccTax + deliveryFee;
    state.order.tip = tip;
    
    if(creditCardProgram === "nonCashFee") {
      state.order.total = total + tip;
      state.order.ccTotal = total + ((subtotal + tax + convenienceFee + deliveryFee) * nonCashFeePercentage) + tip + convenienceFee;
    }
    else {
      state.order.total = total;
      state.order.ccTotal = ccTotal + tip + convenienceFee;
    }
    
    state.nonCashFee = ((subtotal + tax + convenienceFee + deliveryFee) * nonCashFeePercentage).toFixed(2);
  },
  [SET_ORDER_TIPPERCENT]: (state, action) => {
    state.accounting = action.payload;
  }, 
  [SET_ORDER_TOTAL]: (state, action) => {
    state.order.total = action.payload;
  },
  [SET_INSTORE_ORDER]: (state, action) => {
    const { tent, table, kiosk } = action.payload;
    if (table) {
      state.order.tableName = table ? table : "";
      state.order.serviceMode = "dineIn";
    }
    if (tent) {
      state.order.tentName = tent ? tent : "";
      state.order.serviceMode = "dineIn";
    }
    if(kiosk) {
      state.order.kioskName = kiosk ? kiosk : "";
      state.order.serviceMode = "dineIn";
    }
  }
});
